import { PropTypes } from 'prop-types'
import { Stack, Text, HStack, Heading } from '@chakra-ui/react'
import { NewScrollListTitles as ScrollableList } from '@!/Store/Common/components/'
import { CircularProgressModal } from '@!/components/Modal'
import { isBlank } from '@!/Util'
import { IoTrashOutline } from 'react-icons/io5'

export const LatelyAccessList = ({ titles, isLoading, handleResetLog, tabName }) => {
  const sectionTitle = '最近チェックしたマンガ'

  return (
    <>
      <CircularProgressModal isOpen={isLoading} />
      <Stack spacing={0} mx={4} my={3}>
        <HStack spacing={0} mb="1.2em" justifyContent="space-between">
          <Heading fontSize="lg" as="h2" noOfLines={1}>
            {sectionTitle}
          </Heading>
          {!isBlank(titles) && (
            <HStack spacing={0} as="button" onClick={handleResetLog} alignItems="center">
              <Text fontSize="13px" lineHeight="16px">
                履歴を全て削除
              </Text>
              <IoTrashOutline />
            </HStack>
          )}
        </HStack>
        {isBlank(titles) ? (
          <Text fontSize="13px" textAlign="center">
            最近チェックしたマンガはありません。
          </Text>
        ) : (
          <ScrollableList titles={titles} type="book" {...(tabName && { sectionTitle: `${tabName}_lately` })} />
        )}
      </Stack>
    </>
  )
}

LatelyAccessList.propTypes = {
  titles: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleResetLog: PropTypes.func.isRequired,
  tabName: PropTypes.string,
}
