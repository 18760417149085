import { useState } from 'react'
import { useAtom } from 'jotai'
import { useAppCookies } from '@!/Hooks'
import { safeSearchAtom } from '@!/atoms/safeSearchAtom'
import { Flex, Box, Switch, VStack } from '@chakra-ui/react'

export const SafeSearch = () => {
  const { setSafeSearchCookie } = useAppCookies()
  const [safeSearch, setSafeSearch] = useAtom(safeSearchAtom)
  const [isEnabled, setIsEnabled] = useState(safeSearch)

  const handleToggle = () => {
    setIsEnabled(!isEnabled)
    setSafeSearch(!isEnabled)
    setSafeSearchCookie(!isEnabled)
    window.location.reload()
  }

  return (
    <VStack spacing={0}>
      <Flex justifyContent="space-between" alignItems="center" width="100%">
        <Box fontSize="sm" fontWeight="semibold">
          セーフサーチ
        </Box>
        <Switch
          size="lg"
          _focus={{ _focus: 'none' }}
          sx={{ '.chakra-switch__track': { _checked: { bg: 'green.400' }, _focus: { boxShadow: 'none' } } }}
          isChecked={isEnabled}
          onChange={handleToggle}
        />
      </Flex>
    </VStack>
  )
}
