import { useEffect, useState, useMemo } from 'react'
import { useAtom } from 'jotai'
import { Container, VStack, Heading, Text, Link, useToast, Divider, HStack, Box } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

import BookshelfItems from '@!/User/Bookshelf/components/BookshelfItems'
import SortBlock from '@!/User/Bookshelf/components/SortBlock'
import { getEndDate, visibleBookLimitDate } from '@!/User/Bookshelf/util/Check'
import { CircularProgressModal, ConfirmPopupModal } from '@!/components/Modal'
import { storeBookColophonUrl } from '@!/Util/Location'
import { saveBookselfDisplayDefault, getLastOpenBookshelfTime, saveLastOpenBookshelfTime } from '@!/Util/LocalStorageData'
import { breadcrumbAtom } from '@!/atoms/breadcrumbAtom'
import { usePurchaseItems, useReadBook } from '@!/Hooks'
import { purchaseItemsSortedByBuyAtom, purchaseItemsSortedByTitleAtom, searchKeywordAtom } from '@!/atoms/bookShelfAtom'
import { getDevice } from '@!/Util/Device'
import { BookshelfSerachForm } from './BookshelfSerachForm'
import { isBlank } from '@!/Util'

const Bookshelf = () => {
  const navigate = useNavigate()
  const [, purchaseHistories, isSuccess, , isLoading] = usePurchaseItems()
  const [purchaseItemsSortedByBuy] = useAtom(purchaseItemsSortedByBuyAtom)
  const [purchaseItemsSortedByTitle] = useAtom(purchaseItemsSortedByTitleAtom)
  const [, setSearchKeyword] = useAtom(searchKeywordAtom)
  const [books, setBooks] = useState([])
  const [selectedBook, setSelectedBook] = useState(null)
  const [sortType, setSortType] = useState('buy')
  const [removedBooks, setRemovedBooks] = useState([])
  const [readPurchasedBookMutation, redirectUrl, readBookApiError] = useReadBook('purchased')
  const [apiError, setApiError] = useState(false)
  const [apiErrorText, setApiErrorText] = useState('')
  const [confirmModalText, setConfirmModalText] = useState('')
  const [, setBreadcrumb] = useAtom(breadcrumbAtom)
  const toast = useToast()

  const removedBooksFilter = (histories, lastTime, currentTime) => {
    return histories
      .map((book) => {
        const limitDate = visibleBookLimitDate(book)
        // 閲覧期限付コンテンツは期限が過ぎたら除外
        if (book.dl_limit === 1 && lastTime && lastTime < limitDate && limitDate < currentTime) {
          return book.full_name
        }
      })
      .filter((book) => book)
  }

  const removingBooks = useMemo(() => {
    if (!isSuccess) return
    if (isBlank(purchaseHistories.histories)) return

    const lastTime = getLastOpenBookshelfTime()
    const currentTime = Date.parse(purchaseHistories.current_time)
    setRemovedBooks(removedBooksFilter(purchaseHistories.histories, lastTime, currentTime))
  }, [isSuccess, purchaseHistories?.histories, purchaseHistories?.current_time])

  const redirector = useMemo(() => {
    if (!redirectUrl) return

    window.location.href = redirectUrl
  }, [redirectUrl])

  const showApiError = useMemo(() => {
    if (!readBookApiError) return

    setApiError(readBookApiError)
    if (readBookApiError.indexOf('BOOK_EXPIRED') !== -1) {
      setApiErrorText('閲覧期限を過ぎているため表示できません。')
    } else {
      setApiErrorText('エラーが発生しました。')
    }
  }, [readBookApiError])

  const saveCurrentTime = useMemo(() => {
    if (!isSuccess) return

    const currentTime = Date.parse(purchaseHistories.current_time)
    saveLastOpenBookshelfTime(currentTime)
  }, [isSuccess, purchaseHistories?.current_time])

  const saveBooksSortedByBuy = useMemo(() => {
    if (sortType !== 'buy') return

    setBooks(purchaseItemsSortedByBuy)
  }, [sortType, purchaseItemsSortedByBuy])

  const saveBooksSortedByTitle = useMemo(() => {
    if (sortType === 'buy') return

    setBooks(purchaseItemsSortedByTitle)
  }, [sortType, purchaseItemsSortedByTitle])

  removingBooks
  redirector
  showApiError
  saveCurrentTime
  saveBooksSortedByBuy
  saveBooksSortedByTitle

  useEffect(() => {
    setBreadcrumb([{ name: 'マンガBANGブックス', ref: '/' }, { name: '本棚' }])
    saveBookselfDisplayDefault('/book_all')
    setSearchKeyword('')
  }, [])

  useEffect(() => {
    if (isBlank(removedBooks)) return

    const removedTitles = removedBooks.slice(0, 3).join(', ')
    const remainingCount = removedBooks.length - 3
    const additionalText = remainingCount > 0 ? `他${remainingCount}作品` : ''

    toast({
      title: '本棚から削除しました。',
      description: `下記作品は閲覧期限を過ぎています。：${removedTitles} ${additionalText}`,
      status: 'info',
      duration: 9000,
      isClosable: true,
      onCloseComplete: () => {
        setRemovedBooks([])
      },
    })
  }, [removedBooks])

  const handleSearch = (keyword) => {
    setSearchKeyword(keyword)
  }

  const changeSortType = (e, selectedSortType) => {
    e.preventDefault()
    setSortType(selectedSortType)

    const books = selectedSortType === 'buy' ? purchaseItemsSortedByBuy : purchaseItemsSortedByTitle
    setBooks(books)
  }

  const handleClickBook = (book) => {
    const endDate = getEndDate(book)
    if (endDate) {
      // 期限持ちのものは「〜まで閲覧できます。」というアラートを出す。
      setConfirmModalText(`「${book.full_name}」は${endDate}まで閲覧できます。`)
      setSelectedBook(book)
    } else {
      showBook(book)
    }
  }

  const handleConfirmModalPopup = () => {
    if (!selectedBook) return

    showBook(selectedBook)
  }

  const handleClickBookTitles = (e) => {
    e.preventDefault()
    navigate('/title_list')
  }

  const showBook = (book) => {
    const colophonUrl = storeBookColophonUrl(book.mddc_id, false)
    readPurchasedBookMutation.mutate({ bookId: book.mddc_id, colophonUrl: colophonUrl })
    setConfirmModalText('')
    setSelectedBook(null)
  }

  const device = getDevice()
  const showNumber = useMemo(() => {
    return device === 'sp' ? 4 : 5
  }, [device])

  const resetApiError = () => {
    setApiError(null)
    setApiErrorText('')
  }

  return (
    <Container py={6} px={2} boxSizing="border-box">
      <CircularProgressModal isOpen={isLoading || readPurchasedBookMutation.isLoading} />
      <VStack spacing={0} align="stretch">
        <Box px={2}>
          <Heading as="h1" fontSize="2xl">
            本棚
          </Heading>
          <Divider my={3} />
          <BookshelfSerachForm keyword={''} handleSearch={handleSearch} />
          {!isBlank(books) && (
            <HStack spacing={0} fontSize="sm" justifyContent="flex-end" fontWeight="semibold" mt={3} mb={2}>
              <SortBlock sortType={sortType} changeSortType={changeSortType} handleClickBookTitles={handleClickBookTitles} />
              <Link color="label.primary" onClick={(e) => handleClickBookTitles(e)}>
                作品別表示
              </Link>
            </HStack>
          )}
        </Box>

        {!isBlank(books) ? (
          <BookshelfItems books={books} handleClickBook={handleClickBook} showNumber={showNumber} />
        ) : (
          <Text py={10} textAlign="center" fontSize="sm">
            閲覧できる作品がありません。
          </Text>
        )}

        <ConfirmPopupModal handlePopup={() => resetApiError()} text={apiErrorText} isOpen={!!apiError} />
        <ConfirmPopupModal handlePopup={handleConfirmModalPopup} text={confirmModalText} isOpen={!!confirmModalText} />
      </VStack>
    </Container>
  )
}

export default Bookshelf
