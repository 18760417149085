import { PropTypes } from 'prop-types'
import { useBookAccessLog } from '@!/Hooks'
import { LatelyAccessList } from '@!/Store/Common/components/LatelyAccessList'

export const ScrollableLatelyAccessList = ({ tabName }) => {
  const [titles, isLoading, handleResetLog, showLatelyAccessList] = useBookAccessLog()

  return showLatelyAccessList && <LatelyAccessList titles={titles} isLoading={isLoading} handleResetLog={handleResetLog} tabName={tabName} />
}

ScrollableLatelyAccessList.propTypes = {
  tabName: PropTypes.string,
}
