import { isBlank } from './blankCheckers'

export function saveData(key, value) {
  if (!window.localStorage) return

  try {
    if (value) {
      window.localStorage.setItem(key, value)
    } else {
      window.localStorage.removeItem(key)
    }
  } catch {
    // error
  }
}

export function getData(key) {
  return window.localStorage.getItem(key)
}

export function savePurchaseData(value) {
  let data = null
  if (value) {
    data = JSON.stringify(value)
  }
  saveData('purchaseList', data)
}

export function getPurchaseData() {
  const data = getData('purchaseList')
  return data ? JSON.parse(data) : null
}

export function saveLastOpenBookshelfTime(time) {
  saveData('lastOpenBookshelfTime', time)
}

export function getLastOpenBookshelfTime() {
  return getData('lastOpenBookshelfTime')
}

export function saveBookselfDisplayDefault(type) {
  saveData('bookselfDisplayDefault', type)
}

export function getBookselfDisplayDefault() {
  return getData('bookselfDisplayDefault')
}

export function savePreviousPaymentMethod(type) {
  saveData('previousPaymentMethod', type)
}

export function getPreviousPaymentMethod() {
  return getData('previousPaymentMethod')
}

export function saveCartItem(key, item) {
  const currentItems = getCartItems(key) || []

  // 重複アイテムは削除してから追加
  const filteredItems = currentItems.filter((currentItem) => currentItem.distribution_id !== item.distribution_id)
  const newItems = [...filteredItems, item]
  saveData(key, JSON.stringify(newItems))
}

export function saveCartItems(key, items) {
  if (isBlank(items)) return

  const currentItems = getCartItems(key) || []

  // 重複アイテムは削除してから追加
  const filteredItems = currentItems.filter((currentItem) =>
    items.some((item) => {
      currentItem.distribution_id !== item.distribution_id
    }),
  )
  const newItems = [...filteredItems, ...items]
  saveData(key, JSON.stringify(newItems))
}

export function getCartItems(key) {
  const items = getData(key)
  return items ? JSON.parse(items) : []
}

export function resetCartItems(key) {
  saveData(key, JSON.stringify([]))
}

export function removeCartItems(key, distribution_ids) {
  const currentItems = getCartItems(key) || []

  const filteredItems = currentItems.filter((currentItem) => !distribution_ids.includes(currentItem.distribution_id))
  saveData(key, JSON.stringify(filteredItems))
}

const cartItemsKey = 'cartItems'
export function saveCartItemLoggedIn(item) {
  saveCartItem(cartItemsKey, item)
}

export function saveCartItemsLoggedIn(items) {
  saveCartItems(cartItemsKey, items)
}

export function getCartItemsLoggedIn() {
  return getCartItems(cartItemsKey)
}

export function resetCartItemsLoggedIn() {
  resetCartItems(cartItemsKey)
}

export function removeCartItemLoggedIn(distribution_ids) {
  removeCartItems(cartItemsKey, distribution_ids)
}

const cartItemsNotLoggedInKey = 'cartItemsNotLoggedIn'
export function saveCartItemNotLoggedIn(item) {
  saveCartItem(cartItemsNotLoggedInKey, item)
}

export function saveCartItemsNotLoggedIn(items) {
  saveCartItems(cartItemsNotLoggedInKey, items)
}

export function getCartItemsNotLoggedIn() {
  return getCartItems(cartItemsNotLoggedInKey)
}

export function resetCartItemsNotLoggedIn() {
  resetCartItems(cartItemsNotLoggedInKey)
}

export function removeCartItemNotLoggedIn(distribution_ids) {
  removeCartItems(cartItemsNotLoggedInKey, distribution_ids)
}

function getBookAccessLogData() {
  const data = getData('bookAccessLog')
  return data ? JSON.parse(data) : []
}

const EXPIRATION_DAYS = 30
const LOG_LIMIT = 10
export function getBookAccessLog() {
  const books = getBookAccessLogData()

  const now = new Date()
  return books.filter((book) => new Date(book.expiration) > now).map((book) => ({ id: book.id, name: book.name, image_url: book.image_url }))
}

export function saveBookAccessLog(distributionId, titleName, imageUrl) {
  const currentBooks = getBookAccessLogData()

  const expirationDate = new Date()
  expirationDate.setDate(expirationDate.getDate() + EXPIRATION_DAYS)

  const updatedBooks = [
    { id: distributionId, name: titleName, image_url: imageUrl, expiration: expirationDate },
    ...currentBooks.filter((book) => book.id !== distributionId),
  ]

  const bookAccessLog = updatedBooks.slice(0, LOG_LIMIT)

  saveData('bookAccessLog', JSON.stringify(bookAccessLog))
}

export function resetBookAccessLog() {
  saveData('bookAccessLog', JSON.stringify([]))
}
