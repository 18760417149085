import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQueryClient } from 'react-query'
import { getGmoToken } from '@!/Store/Common/util/Payment'
import { usePaymentSaveCard } from '@!/Hooks'
import { savePreviousPaymentMethod } from '@!/Util/LocalStorageData'

import CreditCardForm from '@!/Store/Purchase/components/CreditCardForm'
import { CircularProgressModal } from '@!/components/Modal'
import { isBlank } from '@!/Util'

const CreditCardFormContainer = ({ cardInfo, setCardInfo, setShowNewCardForm, tokenApiUrl, shopId }) => {
  const queryClient = useQueryClient()
  const [newCardInfo, setNewCardInfo] = useState({
    cardNumber: '',
    expirationMonth: '',
    expirationYear: '',
    securityCode: '',
    cardholderName: '',
  })
  const [newCardError, setNewCardError] = useState(null)

  const [saveCardMutation, saveCardApiError] = usePaymentSaveCard()

  useEffect(() => {
    if (!saveCardMutation.isSuccess) return

    savePreviousPaymentMethod('credit')
    setShowNewCardForm(false)
    setCardInfo({
      number: newCardInfo.cardNumber,
      name: newCardInfo.cardholderName,
      month: newCardInfo.expirationMonth,
      year: newCardInfo.expirationYear,
      securityCode: newCardInfo.securityCode,
    })
  }, [saveCardMutation.isSuccess, queryClient])

  useEffect(() => {
    if (!saveCardMutation.isError) return

    if (saveCardApiError.error === 'fraudulent_registration') {
      setNewCardError({
        error: '登録回数が上限に達しました。時間をおいて再度お試しください。',
        field: null,
      })
    } else if (saveCardApiError.error === 'not_registered' || saveCardApiError.error === 'token_expire') {
      setNewCardError({
        error: '登録に失敗しました。再度入力をお願いします。',
        field: null,
      })
    } else if (saveCardApiError.error === 'card_info') {
      setNewCardError({
        error: '登録に失敗しました。入力内容をご確認ください。',
        field: null,
      })
    } else if (saveCardApiError.error) {
      setNewCardError({
        error: saveCardApiError.error,
        field: null,
      })
    }
  }, [saveCardMutation.isError])

  const validateCardInfo = (newCardInfo) => {
    if (!newCardInfo.cardNumber) {
      return { error: 'クレジットカード番号を入力してください。', field: 'cardNumber' }
    }

    if (!newCardInfo.expirationMonth || !newCardInfo.expirationYear) {
      const field = !newCardInfo.expirationMonth ? 'expirationMonth' : 'expirationYear'
      return { error: '利用期限を入力してください。', field: field }
    }

    if (!newCardInfo.cardholderName) {
      return { error: 'カード名義人を入力してください。', field: 'cardholderName' }
    }

    if (!newCardInfo.securityCode) {
      return { error: 'セキュリティコードを入力してください。', field: 'securityCode' }
    }

    const month = newCardInfo.expirationMonth.padStart(2, '0')
    const year = '20' + newCardInfo.expirationYear

    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth() + 1

    if (parseInt(month) < 1 || parseInt(month) > 12) {
      return { error: '正しい月を入力してください。', field: 'expirationMonth' }
    }

    if (parseInt(year) < currentYear || (parseInt(year) === currentYear && parseInt(month) < currentMonth)) {
      return { error: 'カードの有効期限が切れています', field: 'expirationYear' }
    }

    return {
      params: {
        cardno: newCardInfo.cardNumber,
        holdername: newCardInfo.cardholderName,
        expire: year + month,
        securitycode: newCardInfo.securityCode,
      },
    }
  }

  const checkGmoResultCode = (gmoResponse) => {
    if (gmoResponse.resultCode == '000') {
      return { token: gmoResponse.tokenObject.token }
    }

    switch (gmoResponse.resultCode) {
      case 100:
      case 101:
      case 102:
        return { error: 'カード番号が正しくありません。', field: 'cardNumber' }
      case 110:
      case 111:
      case 112:
      case 113:
        return { error: '有効期限が正しくありません。', field: 'expirationYear' }
      case 121:
      case 122:
        return { error: 'セキュリティーコードが正しくありません。', field: 'securityCode' }
      case 131:
      case 132:
        return { error: '名義が正しくありません。', field: 'cardholderName' }
      default:
        return { error: 'エラーが発生しました。', field: null }
    }
  }

  const handleSaveCreditCard = async (e) => {
    e.preventDefault()

    const validation = validateCardInfo(newCardInfo)

    if (validation.error) {
      setNewCardError(validation)
      return
    }

    try {
      const gmoResponse = await getGmoToken(tokenApiUrl, shopId, validation.params)
      const gmoResult = checkGmoResultCode(gmoResponse)

      if (gmoResult.token) {
        saveCardMutation.mutate({ token: gmoResult.token, isUpdate: !isBlank(cardInfo) })
      } else {
        setNewCardError(gmoResult)
      }
    } catch {
      setNewCardError({
        error: 'クレジットカード情報を保存できませんでした。',
        field: null,
      })
    }
  }

  return (
    <>
      <CircularProgressModal isOpen={saveCardMutation.isLoading} />
      <CreditCardForm newCardInfo={newCardInfo} setNewCardInfo={setNewCardInfo} newCardError={newCardError} handleSaveCreditCard={handleSaveCreditCard} />
    </>
  )
}

CreditCardFormContainer.propTypes = {
  cardInfo: PropTypes.object,
  setCardInfo: PropTypes.func,
  setShowNewCardForm: PropTypes.func.isRequired,
  tokenApiUrl: PropTypes.string.isRequired,
  shopId: PropTypes.string.isRequired,
}

export default CreditCardFormContainer
