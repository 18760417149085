import { useEffect } from 'react'
import { Box, Center, Link, Image } from '@chakra-ui/react'
import { ScrollableLatelyAccessList } from '@!/Store/Common/components'
import { useResetByLogin, useJumpLastUrl } from '@!/Hooks'

export const ActivationComplete = (props) => {
  const coupon = props.params.coupon
  const executeResetData = useResetByLogin()
  const jumpLastUrl = useJumpLastUrl()

  useEffect(() => {
    executeResetData()
  }, [])

  return (
    <Box py={4}>
      <Center py={4} textAlign="center">
        メール認証を完了しました。
        <br />
        引き続きマンガBANGブックスをお楽しみください
      </Center>
      <Center pt={4}>
        <Link onClick={jumpLastUrl}>元のページに戻る</Link>
      </Center>
      {coupon && (
        <Box margin="0 auto" my={8} px={4} maxW="430px">
          <Image src={coupon.image_url} alt={coupon.title} w="100%" />
        </Box>
      )}
      <ScrollableLatelyAccessList />
      <Center mt={8} mb={4}>
        <Link href="/">HOMEへ</Link>
      </Center>
    </Box>
  )
}
