import { calculateDiscountPrice } from './calculatePurchasePrices'

describe('calculateDiscountPrice', () => {
  describe('初回割引の適用', () => {
    it('割引額が割引上限額に達しない場合は、計算された割引額が返されること', () => {
      const basePrice = 1000
      const currentCoupon = {
        coupon_type: 'first_time_discount',
        discount_rate: 90,
        limit_amount: 1000,
      }

      const discountPrice = calculateDiscountPrice(basePrice, currentCoupon)

      expect(discountPrice).toBe(900)
    })

    it('割引額が割引上限額を超える場合は、割引上限額が返されること', () => {
      const basePrice = 2000
      const currentCoupon = {
        coupon_type: 'first_time_discount',
        discount_rate: 90,
        limit_amount: 1000,
      }

      const discountPrice = calculateDiscountPrice(basePrice, currentCoupon)

      expect(discountPrice).toBe(1000)
    })
  })
})
