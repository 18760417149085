import { useEffect } from 'react'
import { useAtom } from 'jotai'
import { css } from 'aphrodite'
import PropTypes from 'prop-types'

import styles from '@!/Announcements/styles/AnnouncementsStyle'
import { useAnnouncements } from '@!/Hooks'
import { breadcrumbAtom } from '@!/atoms/breadcrumbAtom'
import { isBlank } from '@!/Util'

const AnnouncementItem = ({ data }) => {
  const startsAt = new Date(data.starts_at)
  return (
    <li className={css(styles.announcements_list_item)}>
      <a href={`/announcements#detail?id=${data.id}`} className={css(styles.announcements_list_link)}>
        <div className={css(styles.announcements_list_box)}>
          <div className={css(styles.announcements_list_title)}>{data.title}</div>
          <div className={css(styles.announcements_list_date)}>{`${startsAt.getFullYear()}/${startsAt.getMonth() + 1}/${startsAt.getDate()}`}</div>
        </div>
      </a>
    </li>
  )
}

AnnouncementItem.propTypes = {
  data: PropTypes.object.isRequired,
}

const Announcements = () => {
  const { data: announcements } = useAnnouncements()
  const [, setBreadcrumb] = useAtom(breadcrumbAtom)

  useEffect(() => {
    setBreadcrumb([{ name: 'マンガBANGブックス', ref: '/' }, { name: 'お知らせ' }])
  }, [])

  return (
    <div style={{ maxWidth: '1080px', margin: '0 auto' }}>
      <h1 className={css(styles.announcements_main_title)}>お知らせ</h1>
      <ul className={css(styles.announcements_list)}>
        {!isBlank(announcements) ? (
          announcements.map((announcement) => <AnnouncementItem key={announcement.id} data={announcement} />)
        ) : (
          <div style={{ textAlign: 'center' }}>お知らせはありません。</div>
        )}
      </ul>
    </div>
  )
}

export default Announcements
