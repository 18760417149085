import { Button } from '@chakra-ui/react'

const GoBackButton = ({ onClick, children }) => (
  <Button
    onClick={onClick}
    my={6}
    p={6}
    border="1px solid #EEE"
    bg="#EEE"
    borderRadius="12px"
    width="65%"
    boxShadow="0 1px 6px rgba(36, 33, 33, 0.28)"
    color="#484848"
    fontWeight="normal"
    textAlign="center"
  >
    {children}
  </Button>
)

export default GoBackButton
