import { useState, useEffect, useCallback } from 'react'
import { getBookAccessLog, resetBookAccessLog } from '@!/Util/LocalStorageData'
import { isBlank } from '@!/Util'

export const useBookAccessLog = () => {
  const [titles, setTitles] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showLatelyAccessList, setShowLatelyAccessList] = useState(false)

  useEffect(() => {
    const titles = getBookAccessLog()
    setShowLatelyAccessList(!isBlank(titles))
    setTitles(titles)
  }, [])

  const handleResetLog = useCallback(() => {
    setIsLoading(true)
    resetBookAccessLog()

    setTimeout(() => {
      setIsLoading(false)
      setTitles([])
    }, 500)
  }, [])

  return [titles, isLoading, handleResetLog, showLatelyAccessList]
}
