import { useJumpLastUrl } from '@!/Hooks'
import { VStack, Text } from '@chakra-ui/react'
import GoBackButton from '@!/StaticPage/components/GoBackButton'

export const PaymentComplete = (props) => {
  const jumpLastUrl = useJumpLastUrl()
  const { error, error_type, cancel } = props.params
  let text = 'コインの購入を完了しました。'
  if (cancel) {
    text = 'コインの購入をキャンセルしました。'
  } else if (error) {
    switch (error_type) {
      case 'coin_add_failure':
        text = '申し訳ありません、コインの付与に失敗しました。恐れ入りますが、メニューの「お問い合わせ」よりサポートまでご連絡ください。'
        break
      default:
        text = '決済に失敗しました。再度コイン購入を行ってください。'
    }
  }

  return (
    <VStack spacing={0} p={3}>
      <Text fontSize="lg" fontWeight="bold">
        {text}
      </Text>
      {cancel || error ? (
        <GoBackButton onClick={() => (window.location.href = '/user/payment')}>コイン購入ページに戻る</GoBackButton>
      ) : (
        <GoBackButton onClick={jumpLastUrl}>元のページに戻る</GoBackButton>
      )}
    </VStack>
  )
}
