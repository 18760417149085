import { atom } from 'jotai'
import { isBlank } from '@!/Util'

export const purchaseItemsAtom = atom({})
purchaseItemsAtom.debugLabel = 'purchaseItems'

// 追加、更新、削除用に1件だけ保持する
export const purchaseItemAtom = atom({})
purchaseItemAtom.debugLabel = 'purchaseItem'

export const createPurchaseItemAtom = atom(
  (get) => !!get(purchaseItemAtom),
  (get, set) => {
    const newPurchaseItem = get(purchaseItemAtom)
    if (newPurchaseItem && newPurchaseItem.distribution_id) {
      set(purchaseItemsAtom, (prev) => [...prev, newPurchaseItem])
      set(purchaseItemAtom, {})
    }
  },
)

export const purchaseItemsArrayAtom = atom((get) => {
  const purchaseItems = get(purchaseItemsAtom)
  if (isBlank(purchaseItems)) return []

  return Object.values(purchaseItems).map((item) => item)
})
