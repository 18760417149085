import { useMemo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Center, Divider, Heading, StackDivider, VStack, Link } from '@chakra-ui/react'
import { Paginate } from '@!/components/Paginate'
import { encodeURIPath } from '@!/Util/String'
import { buildUrlString, isBlank } from '@!/Util'
import { ListItem, SearchGuidePopupModal, SafeSearchText } from '@!/Store/SearchList/components'
import { SearchForm, TagSearchBox } from '@!/Store/Common/containers'
import { TopTabs, ScrollableLatelyAccessList } from '@!/Store/Common/components'
import { PointBackBanner } from '@!/components/Banner'
import { useAtom } from 'jotai'
import { userHashAtom } from '@!/atoms/userInfoAtom'
import { useCheckPurchasedBooks, useTabs } from '@!/Hooks'
import { breadcrumbAtom } from '@!/atoms/breadcrumbAtom'
import { SafeSearch } from '@!/Menu/components/SafeSearch'
import { menuOpenAtom } from '@!/atoms/appSettingsAtom'

const StoreSearchList = ({ type, result, q: keyword, searchTags, tabName }) => {
  const [, setBreadcrumb] = useAtom(breadcrumbAtom)
  const [userHash] = useAtom(userHashAtom)
  const [bookTitles, setBookTitles] = useState(result.book_titles)
  const distributionIds = useMemo(() => bookTitles.map((bookTitle) => bookTitle.first_volume.distribution_id), [bookTitles])
  const { data: purchasedBookIds, isSuccess: isSuccess } = useCheckPurchasedBooks(userHash, distributionIds)
  const { currentTab } = useTabs(tabName)
  const genreName = currentTab.genreName
  const genreLabel = currentTab.genreLabel
  const tabLabel = currentTab.label
  const [isModalOpen, setModalOpen] = useState(false)
  const [, setIsMenuOpen] = useAtom(menuOpenAtom)

  const openModal = () => setModalOpen(true)
  const closeModal = () => setModalOpen(false)
  const handleMenuOpen = (open) => {
    setIsMenuOpen(open)
  }

  useEffect(() => {
    if (!isSuccess) return

    setBookTitles(
      bookTitles.map((bookTitle) => {
        bookTitle.first_volume.purchased = purchasedBookIds.some((purchasedBookId) => purchasedBookId == bookTitle.first_volume.distribution_id)
        return bookTitle
      }),
    )
  }, [isSuccess])

  const topText = useMemo(() => {
    switch (type) {
      case 'publisher_name':
        return `${keyword}の漫画作品を紹介するページです。${keyword}から配信されている少年漫画、青年漫画、少女漫画を紹介しています。新刊も随時配信されます。${keyword}の漫画作品をお探しの方はこちらからお探しください。`
      case 'author_name':
      case 'magazine':
      case 'label':
        return `${keyword}の漫画作品を紹介するページです。${keyword}の漫画をお探しの方はこちらからお探しください。`
      case 'category_name':
        return `${keyword}ジャンルの漫画作品を紹介するページです。複数の出版社の${keyword}ジャンルの漫画を紹介しています。${keyword}漫画をお探しの方はこちらからお探しください。`
      default:
        return null
    }
  }, [type, keyword])

  useEffect(() => {
    setBreadcrumb([{ name: 'マンガBANGブックス', ref: '/' }, { name: `検索結果(${genreLabel})` }, { name: keyword }])
  }, [type, keyword, genreLabel])

  const buildPath = (page) => {
    let path = null
    let queries = {}
    switch (type) {
      case 'book_title_kana':
        path = `/store/search/book_title_kana`
        queries['q'] = keyword
        break
      case 'category_name':
        path = `/store/search/category/${encodeURIPath(keyword)}`
        break
      case 'publisher_name':
        path = `/store/search/publisher/${encodeURIPath(keyword)}`
        break
      case 'author_name':
        path = `/store/search/author/${encodeURIPath(keyword)}`
        break
      case 'magazine':
        path = `/store/search/magazine/${encodeURIPath(keyword)}`
        break
      case 'label':
        path = `/store/search/label/${encodeURIPath(keyword)}`
        break
      default:
        path = '/store/search'
        queries['q'] = keyword
        queries['type'] = type
    }

    if (page > 1) {
      queries['page'] = page
    }

    if (genreName) {
      queries['genre_name'] = genreName
    }

    return buildUrlString(path, queries)
  }

  const handlePageClick = (page) => {
    window.location.href = buildPath(page)
  }

  return (
    <Box>
      <TopTabs tabName={tabName} />
      <PointBackBanner />
      <Box mt={3}>
        <Box mb={-3}>
          <SearchForm keyword={type === 'keyword' ? keyword : null} genreName={genreName} placeholder={`${genreLabel}｜キーワード（作品、著者など）入力`} />
        </Box>
        <Box mx={4} fontSize="11px" textAlign="right">
          <Link color="label.primary" textDecoration="none" fontWeight="300" onClick={openModal}>
            検索について ▼
          </Link>
        </Box>
      </Box>
      <SearchGuidePopupModal isOpen={isModalOpen} handleClose={closeModal} />
      <Box>
        <Box px="16px" py="12px">
          <Heading as="h1" fontSize="13px" fontWeight="300" lineHeight="18px" mb={2}>
            {genreLabel}からの検索結果「{keyword}」
          </Heading>
          <Box fontSize="11px" fontWeight="300" lineHeight="14px">
            {topText}
          </Box>
          <SafeSearchText showWarningText={result.excluded_titles_present} handleMenuOpen={handleMenuOpen} />
          <Box my={4}>
            {!isBlank(bookTitles) ? (
              <>
                <Divider />
                <VStack spacing={0} divider={<StackDivider />}>
                  {bookTitles.map((bookTitle, index) => (
                    <ListItem key={`store-search-list-item-${index}`} bookTitle={bookTitle} />
                  ))}
                </VStack>
                <Divider />
                <Center mt="24px">
                  <Paginate currentPage={result.current_page + 1} perPage={result.per_page} totalCount={result.total_count} onPageChange={handlePageClick} />
                </Center>
              </>
            ) : (
              <Box>該当する作品は見つかりませんでした。</Box>
            )}
          </Box>
          <Center mb={4}>
            <Link href={currentTab.url} fontSize="13px">
              {genreName ? `${genreLabel}TOP` : tabLabel}に戻る
            </Link>
          </Center>
          <Box py={3} px={4} boxShadow="0px 2px 6px rgba(0, 0, 0, 0.1)">
            <SafeSearch />
          </Box>
        </Box>
        <Divider borderBottomWidth="12px" borderColor="bg.secondary" my={4} />
        <ScrollableLatelyAccessList />
        <Box py={3} px={4}>
          <Divider borderBottomWidth="2px" borderColor="bg.secondary" />
        </Box>
        <TagSearchBox searchTags={searchTags} genreName={genreName} />
      </Box>
    </Box>
  )
}

StoreSearchList.propTypes = {
  type: PropTypes.string,
  q: PropTypes.string,
  result: PropTypes.object,
  searchTags: PropTypes.array.isRequired,
  tabName: PropTypes.string,
}

export default StoreSearchList
