import { useCookies } from 'react-cookie'

export const useAppCookies = () => {
  const [cookies, setCookie, removeCookie] = useCookies()

  const defaultOptions = { path: '/', sameSite: 'strict' }

  const removeUserHashCookie = () => removeCookie('user_hash', defaultOptions)
  const removeLastUrlCookie = () => removeCookie('last_url', defaultOptions)
  const removeCoinCountsCookie = () => removeCookie('coin_counts', defaultOptions)
  const removePointCountsCookie = () => removeCookie('point_counts', defaultOptions)
  const removeSkipCartUpdateFlagCookie = () => removeCookie('updated_carts', defaultOptions)
  const resetUserCookies = () => {
    removeUserHashCookie()
    removeCoinCountsCookie()
    removePointCountsCookie()
    removeSkipCartUpdateFlagCookie()
  }

  const setLastUrlCookie = (lastUrl) => {
    setCookie('last_url', lastUrl, defaultOptions)
  }

  const getOneYearFromNow = () => {
    const oneYearFromNow = new Date()
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1)
    return oneYearFromNow
  }

  const setSafeSearchCookie = (safeSearchSetting) => {
    setCookie('safe_search', safeSearchSetting, {
      ...defaultOptions,
      expires: getOneYearFromNow(),
    })
  }

  const setSafeSearchModalConfirmed = (isConfirmed) => {
    setCookie('safe_search_modal_confirmed', isConfirmed, {
      ...defaultOptions,
      expires: getOneYearFromNow(),
    })
  }

  return {
    userHashCookie: cookies?.user_hash,
    lastUrlCookie: cookies?.last_url,
    coinCountsCookie: cookies?.coin_counts,
    pointCountsCookie: Number(cookies?.point_counts || 0),
    skipCartUpdateFlagCookie: cookies?.updated_carts,
    safeSearchCookie: cookies?.safe_search,
    safeSearchModalConfirmed: cookies?.safe_search_modal_confirmed,
    removeUserHashCookie: removeUserHashCookie,
    removeLastUrlCookie: removeLastUrlCookie,
    removeCoinCountsCookie: removeCoinCountsCookie,
    removePointCountsCookie: removePointCountsCookie,
    removeSkipCartUpdateFlagCookie: removeSkipCartUpdateFlagCookie,
    resetUserCookies: resetUserCookies,
    setLastUrlCookie: setLastUrlCookie,
    setSafeSearchCookie: setSafeSearchCookie,
    setSafeSearchModalConfirmed: setSafeSearchModalConfirmed,
  }
}
