import { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'

import { createCartItemApi } from '@!/Api/Cart'
import { cartItemsKeys } from '@!/Api/queryKeys'
import { isBlank } from '@!/Util'

export const useCreateCartItem = (userHash, detail) => {
  const [apiError, setApiError] = useState(null)

  const queryClient = useQueryClient()
  const createCartItemMutation = useMutation({
    mutationFn: () => createCartItemApi({ distribution_id: detail.distribution_id, version: detail.version }),
    onMutate: async () => {
      const previousData = queryClient.getQueryData(cartItemsKeys.cartItemsLoggedIn(userHash))
      queryClient.setQueryData(cartItemsKeys.cartItemsLoggedIn(userHash), (old) => {
        const filtered = isBlank(old) ? old : old.filter((o) => o.distribution_id === detail.distribution_id)
        return [{ ...detail }, filtered]
      })
      return { previousData }
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(cartItemsKeys.cartItemsLoggedIn(userHash), context.previousData)
      if (error.data?.error) {
        setApiError(error.data.error)
      } else {
        setApiError('server error')
      }
    },
    onSettled: () => {
      // API実行終了時はreact-queryのキャッシュをクリアする
      queryClient.invalidateQueries(cartItemsKeys.cartItemsLoggedIn(userHash))
    },
  })

  return [createCartItemMutation, apiError]
}
