import { useEffect, useState } from 'react'
import { useAtom } from 'jotai'
import { useMutation } from 'react-query'
import { searchCardApi, saveMemberApi } from '@!/Api/Payment'
import { cardInfoAtom, apiTimeAtom } from '@!/atoms/paymentAtom'
import { isBlank } from '@!/Util'

export const useSearchCard = (userHash) => {
  const [, setCardInfo] = useAtom(cardInfoAtom)
  const [, setApiTime] = useAtom(apiTimeAtom)
  const [cardInfoError, setCardInfoError] = useState(null)

  const searchCardMutation = useMutation({
    mutationFn: () => searchCardApi(),
    onSuccess: (data) => {
      const card = data.card
      if (!isBlank(card)) {
        setCardInfo({
          number: card.CardNo,
          name: card.HolderName,
          year: '20' + card.Expire.substring(0, 2),
          month: card.Expire.substring(2, 4),
        })
      } else {
        setCardInfo({})
      }
      setApiTime(data.current_time)
    },
    onError: (error) => {
      if (error.data?.error === 'not_registered') {
        saveMemberMutation.mutate()
      } else {
        setCardInfoError('etc_error')
      }
    },
  })

  const saveMemberMutation = useMutation({
    mutationFn: () => saveMemberApi(),
    onSuccess: (data) => {
      setApiTime(data.current_time)
    },
    onError: () => {
      setCardInfoError('etc_error')
    },
  })

  useEffect(() => {
    if (userHash) {
      searchCardMutation.mutate()
    }
  }, [userHash])

  return [searchCardMutation, cardInfoError]
}
