import { useEffect } from 'react'
import { useAtom } from 'jotai'
import { useToast } from '@chakra-ui/react'

import { usePurchaseHistories } from '@!/Hooks'
import { userHashAtom } from '@!/atoms/userInfoAtom'
import { purchaseItemsAtom } from '@!/atoms/purchaseItemsAtom'
import { savePurchaseData } from '@!/Util/LocalStorageData'
import { isVisibleBook } from '@!/User/Bookshelf/util/Check'
import { isBlank } from '@!/Util'

export const usePurchaseItems = () => {
  const toast = useToast()
  const [userHash] = useAtom(userHashAtom)
  const [purchaseItems, setPurchaseItems] = useAtom(purchaseItemsAtom)
  const { data: purchaseHistories, isSuccess: isSuccess, isError: isError, isLoading: isLoading } = usePurchaseHistories(userHash)

  // 購入履歴取得
  useEffect(() => {
    if (!isSuccess) return
    if (!purchaseHistories) return

    const histories = purchaseHistories.histories
    if (isBlank(histories)) return

    const currentTime = Date.parse(purchaseHistories.current_time)
    const saveList = histories.reduce((accumulator, book) => {
      if (!isVisibleBook(book, currentTime)) return accumulator

      return { ...accumulator, [book.mddc_id]: book }
    }, {})
    savePurchaseData(saveList)
    setPurchaseItems(saveList)
  }, [isSuccess])

  useEffect(() => {
    if (!isError) return

    toast({
      title: 'Notification',
      description: '購入履歴の取得に失敗しました。',
      status: 'error',
      duration: 9000,
      isClosable: true,
    })
  }, [isError])

  // purchaseItemsはisVisibleBookでフィルタされたリスト
  // purchaseHistoriesはAPIから返ってきたraw data
  return [purchaseItems, purchaseHistories, isSuccess, isError, isLoading]
}
