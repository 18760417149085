import { useEffect, useMemo, Fragment, useState } from 'react'
import { useAtom } from 'jotai'
import { Container, VStack, Heading, Link, Text, Divider, HStack } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

import ListItem from '@!/User/Bookshelf/components/ListItem'
import SortBlock from '@!/User/Bookshelf/components/SortBlock'
import { saveBookselfDisplayDefault, saveLastOpenBookshelfTime } from '@!/Util/LocalStorageData'
import { usePurchaseItems } from '@!/Hooks'
import { bookTitlesSortedByBuyAtom, bookTitlesSortedByTitleAtom, searchKeywordAtom } from '@!/atoms/bookShelfAtom'
import { breadcrumbAtom } from '@!/atoms/breadcrumbAtom'
import { getDevice } from '@!/Util/Device'
import { BookshelfSerachForm } from './BookshelfSerachForm'
import { isBlank } from '@!/Util'

const BookshelfList = () => {
  const navigate = useNavigate()
  const [, purchaseHistories, isSuccess] = usePurchaseItems()
  const [bookTitlesSortedByBuy] = useAtom(bookTitlesSortedByBuyAtom)
  const [bookTitlesSortedByTitle] = useAtom(bookTitlesSortedByTitleAtom)
  const [, setSearchKeyword] = useAtom(searchKeywordAtom)
  const [bookTitles, setBookTitles] = useState([])
  const [sortType, setSortType] = useState('buy')
  const [, setBreadcrumb] = useAtom(breadcrumbAtom)
  const device = useMemo(() => getDevice(), [])

  const saveBookTitlesSortedByBuy = useMemo(() => {
    if (sortType !== 'buy') return

    setBookTitles(bookTitlesSortedByBuy)
  }, [sortType, bookTitlesSortedByBuy])

  const saveBookTitlesSortedByTitle = useMemo(() => {
    if (sortType === 'buy') return

    setBookTitles(bookTitlesSortedByTitle)
  }, [sortType, bookTitlesSortedByTitle])

  saveBookTitlesSortedByBuy
  saveBookTitlesSortedByTitle

  useEffect(() => {
    setBreadcrumb([{ name: 'マンガBANGブックス', ref: '/' }, { name: '本棚(作品別)' }])
    saveBookselfDisplayDefault('/title_list')
    setSearchKeyword('')
  }, [])

  useEffect(() => {
    if (!isSuccess) return

    const currentTime = Date.parse(purchaseHistories.current_time)
    saveLastOpenBookshelfTime(currentTime)
  }, [isSuccess])

  const handleSearch = (keyword) => {
    setSearchKeyword(keyword)
  }

  const handleClickBooksMenu = (e) => {
    e.preventDefault()
    navigate('/book_all')
  }

  const handleClickBookTitle = (bookTitle) => {
    navigate(`/title_books?book_title_id=${bookTitle.bookTitleId}`)
  }

  const changeSortType = (e, selectedSortType) => {
    e.preventDefault()
    setSortType(selectedSortType)

    const bookTitles = selectedSortType === 'buy' ? bookTitlesSortedByBuy : bookTitlesSortedByTitle
    setBookTitles(bookTitles)
  }

  return (
    <Container py={6} boxSizing="border-box">
      <VStack spacing={0} align="stretch">
        <Heading as="h1" fontSize="2xl">
          本棚
        </Heading>
        <Divider my={3} />
        <BookshelfSerachForm keyword={''} handleSearch={handleSearch} />

        {!isBlank(bookTitles) && (
          <HStack spacing={0} fontSize="sm" justifyContent="end" fontWeight="semibold" mt={3} mb={2}>
            <SortBlock sortType={sortType} changeSortType={changeSortType} handleClickBookTitles={handleClickBooksMenu} />
            <Link color="label.primary" onClick={handleClickBooksMenu}>
              巻別表示
            </Link>
          </HStack>
        )}

        {isBlank(bookTitles) ? (
          <Text py={10} textAlign="center" fontSize="sm">
            閲覧できる作品がありません。
          </Text>
        ) : (
          <>
            {bookTitles.map((bookTitle, index) => (
              <Fragment key={bookTitle.bookTitleId}>
                {index !== 0 && <Divider my={3} />}
                <ListItem item={bookTitle} handleClickBookTitle={handleClickBookTitle} device={device} />
              </Fragment>
            ))}
          </>
        )}
      </VStack>
    </Container>
  )
}

export default BookshelfList
