import { PropTypes } from 'prop-types'

import { Box, Center, Link, Stack, Text } from '@chakra-ui/react'

import { PointCount, PointTable } from './'
import { isBlank } from '@!/Util'

export const PointAndTable = ({ pointCount, pointList }) => {
  if (isBlank(pointList)) {
    return (
      <Box backgroundColor={'#FFF'} minH={'600px'} py={6} px={4}>
        <Text textAlign="center">ポイントを取得していません。</Text>
      </Box>
    )
  }

  return (
    <Box backgroundColor={'#FFF'} minH={'600px'} py={2}>
      <Stack spacing={0}>
        <Box p={4} boxSizing="border-box">
          <PointCount pointCount={pointCount} />
        </Box>
        <PointTable pointList={pointList} />
        <Center my={4}>
          <Link as="a" href="/user/book_purchase_histories">
            作品購入履歴はこちら
          </Link>
        </Center>
      </Stack>
    </Box>
  )
}

PointAndTable.propTypes = {
  pointCount: PropTypes.number.isRequired,
  pointList: PropTypes.arrayOf(
    PropTypes.shape({
      expire_date: PropTypes.string.isRequired,
      point_count: PropTypes.number.isRequired,
    }),
  ),
}
