import PropTypes from 'prop-types'
import { Box } from '@chakra-ui/react'

import {
  SpecifiedCommercialTransaction,
  Terms,
  PrivacyPolicy,
  Help,
  FundSettlement,
  PaymentComplete,
  ActivationComplete,
  StopPiracy,
  Guide,
  Event,
} from '@!/StaticPage/components'

const StaticPage = ({ pageType, params }) => {
  let component = null
  switch (pageType) {
    case 'specified_commercial_transaction':
      component = <SpecifiedCommercialTransaction />
      break
    case 'terms':
      component = <Terms />
      break
    case 'privacy_policy':
      component = <PrivacyPolicy />
      break
    case 'help':
      component = <Help />
      break
    case 'fund_settlement':
      component = <FundSettlement />
      break
    case 'payment_complete':
      component = <PaymentComplete params={params} />
      break
    case 'activation_complete':
      component = <ActivationComplete params={params} />
      break
    case 'stop_piracy':
      component = <StopPiracy />
      break
    case 'guide':
      component = <Guide params={params} />
      break
    case 'event':
      component = <Event params={params} />
      break
    default:
      break
  }

  return <Box>{component}</Box>
}

StaticPage.propTypes = {
  pageType: PropTypes.string.isRequired,
  params: PropTypes.object, // `params`がundefinedの場合もあるため、isRequiredは不要
}

export default StaticPage
