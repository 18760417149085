import { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box, Link, Text, Flex } from '@chakra-ui/react'
import CustomRadio from '@!/Store/Common/components/CustomRadio'
import CreditCardFormContainer from '@!/Store/Purchase/containers/CreditCardFormContainer'
import { isBlank } from '@!/Util'

const PaymentMethod = ({
  cardInfo,
  setCardInfo,
  coins,
  previousPaymentMethod,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  tokenApiUrl,
  shopId,
  handleGoCoinPurchase,
  isDisabled,
}) => {
  const [showNewCardForm, setShowNewCardForm] = useState(true)
  const [paymentMethods, setPaymentMethods] = useState([])
  const hasCardInfo = useMemo(() => !isBlank(cardInfo), [cardInfo])

  useEffect(() => {
    if (previousPaymentMethod !== null) {
      if (previousPaymentMethod === 'credit' && !cardInfo) {
        setPaymentMethods(availablePaymentMethods)
        setSelectedPaymentMethod('coin')
      } else {
        setPaymentMethods(availablePaymentMethodsFilter(previousPaymentMethod))
      }
    } else {
      if (hasCardInfo) {
        setPaymentMethods(availablePaymentMethodsFilter('credit'))
        setSelectedPaymentMethod('credit')
      } else {
        setPaymentMethods(availablePaymentMethods)
      }
    }

    if (hasCardInfo) {
      setShowNewCardForm(false)
    }
  }, [previousPaymentMethod, cardInfo])

  const handlePaymentMethodChange = (value) => {
    setSelectedPaymentMethod(value)
    if (value === 'credit' && hasCardInfo) {
      setShowNewCardForm(false)
    }
  }

  const handleChangeCardInfo = (e) => {
    e.preventDefault()
    setShowNewCardForm(true)
  }

  const handleChangePaymentMethod = (e) => {
    e.preventDefault()
    setPaymentMethods(availablePaymentMethods)
  }

  const availablePaymentMethods = [
    {
      id: 'coin',
      value: 'coin',
      label: `コイン（保有コイン${coins} Ⅽ）`,
      additionalComponent: (
        <Box marginLeft="auto">
          <Link href="#" onClick={handleGoCoinPurchase}>
            購入する
          </Link>
        </Box>
      ),
    },
    {
      id: 'credit',
      value: 'credit',
      label: 'クレジットカード',
      additionalComponent: hasCardInfo && <Box>{cardInfo.number.slice(-4)}</Box>,
    },
  ]

  const availablePaymentMethodsFilter = (paymentMethodName) => {
    return availablePaymentMethods.filter((method) => {
      return method.value === paymentMethodName
    })
  }

  return (
    <>
      {paymentMethods.map((method) => (
        <Box padding="16px" background="#ffffff" border="1px solid #e0e0e0" borderRadius="4px" key={method.id}>
          <Flex onClick={() => !isDisabled && handlePaymentMethodChange(method.value)} opacity={isDisabled ? 0.5 : 1}>
            <CustomRadio isChecked={selectedPaymentMethod === method.value} />
            <Text mx={2}>{method.label}</Text>
            <Box marginLeft="auto">{method.additionalComponent}</Box>
          </Flex>
          {paymentMethods.length === 1 ? (
            <Box textAlign="center" marginTop="13px">
              <Link href="#" onClick={isDisabled ? null : handleChangePaymentMethod}>
                決済方法を変更する
              </Link>
            </Box>
          ) : (
            <>
              {method.id === 'credit' && selectedPaymentMethod === 'credit' && hasCardInfo && (
                <Box textAlign="center" marginTop="13px">
                  <Link href="#" onClick={handleChangeCardInfo}>
                    カード情報を変更する
                  </Link>
                </Box>
              )}
              {showNewCardForm && method.id === 'credit' && selectedPaymentMethod === 'credit' && (
                <CreditCardFormContainer
                  cardInfo={cardInfo}
                  setCardInfo={setCardInfo}
                  setShowNewCardForm={setShowNewCardForm}
                  tokenApiUrl={tokenApiUrl}
                  shopId={shopId}
                />
              )}
            </>
          )}
        </Box>
      ))}
    </>
  )
}

PaymentMethod.propTypes = {
  cardInfo: PropTypes.object,
  setCardInfo: PropTypes.func,
  coins: PropTypes.number.isRequired,
  previousPaymentMethod: PropTypes.string,
  selectedPaymentMethod: PropTypes.string.isRequired,
  setSelectedPaymentMethod: PropTypes.func.isRequired,
  tokenApiUrl: PropTypes.string.isRequired,
  shopId: PropTypes.string.isRequired,
  handleGoCoinPurchase: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
}

export default PaymentMethod
