import Big from 'big.js'
import { toTaxExcludedPrice } from '@!/Store/Common/util'

export const calculatePurchasePrices = (books, isCouponApplied, currentCoupon, pointValue) => {
  const basePrice = calculatePrice(books)
  if (isCouponApplied) {
    return calculateCouponAppliedPurchasePrices(basePrice, currentCoupon, pointValue)
  } else {
    return calculateNoCouponPurchasePrices(basePrice, pointValue)
  }
}

const calculateCouponAppliedPurchasePrices = (basePrice, currentCoupon, pointValue) => {
  const discountPrice = calculateDiscountPrice(basePrice, currentCoupon)
  const couponAppliedPrice = basePrice - discountPrice
  const totalPrice = couponAppliedPrice - pointValue
  const taxExcludedPrice = toTaxExcludedPrice(totalPrice)

  return { basePrice, discountPrice, couponAppliedPrice, totalPrice, taxExcludedPrice }
}

const calculateNoCouponPurchasePrices = (basePrice, pointValue) => {
  const totalPrice = basePrice - pointValue
  const taxExcludedPrice = toTaxExcludedPrice(totalPrice)

  return { basePrice, discountPrice: null, couponAppliedPrice: null, totalPrice, taxExcludedPrice }
}

// 税込価格
const calculatePrice = (books) => {
  let price = 0

  if (books) {
    books.forEach((book) => {
      price += book.price
    })
  }
  return price
}

export const calculateDiscountPrice = (basePrice, currentCoupon) => {
  if (currentCoupon.coupon_type === 'first_time_discount') {
    // 割引額は basePrice (税込価格) × discountRate (クーポン割引率) 、小数点以下切り捨て
    // 割引額が limitAmount (割引上限額) を超える場合、割引上限額を割引額として返す
    const discountFromRate = new Big(basePrice).times(currentCoupon.discount_rate).div(100).round(0, 0).toNumber()
    return Math.min(discountFromRate, currentCoupon.limit_amount)
  } else {
    // 最低購入金額を満たしていない場合は割引なし
    // 満たしている場合は割引金額を返す
    if (basePrice < currentCoupon.min_purchase_amount) return null
    return currentCoupon.discount_amount
  }
}
